/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import {
    SPAN,
    NODISPLAY,
    DIV,
    BUTTON,
    NODISPLAYBUTTON,
    DETAILS,
    SUMMARY,
} from './elements';
import { getLang, getLanguages, setLang } from '../app';
import { MessageRecordLang } from '../source';
import { Translated } from '../locale';
import { activity, langAction } from '../activity';
import { icon } from './button';

const logger = debug('sdi:lang-switch');

const logLang = (namespace: string, lang: MessageRecordLang) =>
    activity(namespace)(langAction(lang));

const sl = (lc: MessageRecordLang) => setLang(lc);

// ns is the app name
export const switcher = (ns: string) => {
    const lc = getLang();
    const languages = getLanguages();
    if (languages.length == 2) {
        const lang1 = languages[0];
        const lang2 = languages[1];
        return switcher2(ns, lc, lang1, lang2);
    } else if (languages.length > 2) {
        return switcherMore(ns, lc, languages);
    }
    return NODISPLAY();
};

export const switcher2 = (
    ns: string,
    currentLang: MessageRecordLang,
    lang1: MessageRecordLang,
    lang2: MessageRecordLang
) => {
    switch (currentLang) {
        case lang1:
            return BUTTON(
                {
                    className: 'lang-switch',
                    onClick: () => {
                        sl(lang2);
                        logLang(ns, lang2);
                    },
                },

                icon('comment'),
                SPAN('lang-switch-label', lang2.toUpperCase())
            );

        case lang2:
            return BUTTON(
                {
                    className: 'lang-switch',
                    onClick: () => {
                        sl(lang1);
                        logLang(ns, lang1);
                    },
                },

                icon('comment'),
                SPAN('lang-switch-label', lang1.toUpperCase())
            );

        default:
            return NODISPLAYBUTTON();
    }
};

export const switcherMore = (
    ns: string,
    currentLang: MessageRecordLang,
    languages: MessageRecordLang[]
) =>
    DETAILS(
        'lang-switch',
        ...languages
            .filter(l => l !== currentLang)
            .map(l =>
                DIV(
                    {
                        className: 'lang',
                        onClick: () => {
                            sl(l);
                            logLang(ns, l);
                        },
                    },
                    l
                )
            ),
        SUMMARY('lang', currentLang)
    );

export const switcherLarge = (
    label: () => Translated,
    lc: MessageRecordLang
) => {
    switch (lc) {
        case 'fr':
            return DIV(
                'lang-switch-large',
                DIV('lang-switch-lang', `NL`),
                DIV('lang-switch-label', label())
            );

        case 'nl':
            return DIV(
                'lang-switch-large',
                DIV('lang-switch-label', label()),
                DIV('lang-switch-lang', `FR`)
            );

        default:
            return NODISPLAY();
    }
};

// export const switcherLargeNoLabel =
//     () => {
//         const lc = getLang();
//         switch (lc) {
//             case 'fr':
//                 return (DIV({
//                     className: 'lang-switch-large',
//                     onClick: sl('nl'),
//                 },
//                     DIV({ className: 'lang-switch-lang' }, `${tr.core('switchLang')}`),
//                 ));

//             case 'nl':
//                 return (DIV({
//                     className: 'lang-switch-large',
//                     onClick: sl('fr'),
//                 },
//                     DIV({ className: 'lang-switch-lang' }, `${tr.core('switchLang')}`),
//                 ));

//             default:
//                 return NODISPLAY();
//         }
//     };

export default switcher;

logger('loaded');
