/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { getLang, getAlias, findTerm } from '../../app';
import tr, { formatNumber, fromRecord } from '../../locale';
import { DIV, A, IMG, NodeOrOptional } from '../elements';
import {
    RowConfig,
    StringConfig,
    URLConfig,
    ImageConfig,
    ConfigWithLabel,
    BooleanConfig,
    NumberConfig,
    withLabel,
    Feature,
    FeatureViewConfig,
    HTMLConfig,
    TextConfig,
    TermConfig,
    PropType,
} from '../../source';

import piechart from './piechart';
import timeserie from './timeserie';
import { TimeseriePlotter } from '../timeserie';

import { renderDefault } from './index';
import { fromNullable, none, some } from 'fp-ts/lib/Option';
import {
    ensureArray,
    isNotNullNorUndefined,
    tryBoolean,
    tryNumber,
    tryString,
} from '../../util';
import { catOptions } from 'fp-ts/lib/Array';

interface NotNullProperties {
    [key: string]: unknown;
}

const JOIN = '; ';

const WIDGET_TIMESERIE = '__WIDGET_TIMESERIE__';
const WIDGET_PIECHART = '__WIDGET_PIECHART__';
const WIDGET_TEXT = '__WIDGET_TEXT__';
export const WIDGETS = [WIDGET_PIECHART, WIDGET_TIMESERIE, WIDGET_TEXT];
export const WIDGET_NAME: { [k: string]: PropType } = {
    [WIDGET_PIECHART]: 'piechart',
    [WIDGET_TIMESERIE]: 'timeserie',
    [WIDGET_TEXT]: 'text',
};

export const isWidget = (pn: string) =>
    WIDGETS.reduce((acc, w) => {
        if (acc) {
            return acc;
        }
        return w === pn;
    }, false);

const renderString = (props: NotNullProperties, row: StringConfig) => {
    const val = props[row.propName];
    return DIV(
        `type-${row.type} level-${row.options.level} style-${row.options.style}`,
        `${val}`
    );
};

const renderTerm = (props: NotNullProperties, row: TermConfig) =>
    fromNullable(props[row.propName]).map(val =>
        DIV(
            `type-${row.type} level-${row.options.level} style-${row.options.style}`,
            ensureArray(val)
                .map(id =>
                    tryNumber(id)
                        .chain(findTerm)
                        .map<string>(t => fromRecord(t.name))
                        .getOrElse(`${id}`)
                )
                .join(JOIN)
        )
    );
const renderNumber = (props: NotNullProperties, row: NumberConfig) =>
    fromNullable(props[row.propName]).map(val =>
        DIV(
            `type-${row.type} level-${row.options.level} style-${row.options.style}`,
            catOptions(ensureArray(val).map(tryNumber))
                .map(formatNumber)
                .join(JOIN)
        )
    );

const renderBoolean = (props: NotNullProperties, row: BooleanConfig) =>
    fromNullable(props[row.propName]).map(val =>
        DIV(
            `type-${row.type} level-${row.options.level} style-${row.options.style}`,
            catOptions(ensureArray(val).map(tryBoolean))
                .map(n => (n ? tr.core('true') : tr.core('false')))
                .join(JOIN)
        )
    );

const renderUrl = (props: NotNullProperties, row: URLConfig) =>
    fromNullable(props[row.propName]).map(v =>
        DIV(
            `type-${row.type} level-${row.options.level} style-${row.options.style}`,
            ...ensureArray(v).map(e =>
                tryString(e).map(val =>
                    DIV(
                        '',
                        A(
                            {
                                // className: `type-${row.type} level-${row.options.level} style-${row.options.style}`,
                                href: val,
                                target: '_blank',
                            },
                            val
                        )
                    )
                )
            )
        )
    );

const renderImage = (props: NotNullProperties, row: ImageConfig) =>
    fromNullable(props[row.propName])
        .chain(tryString)
        .map(val =>
            IMG({
                className: `type-image`,
                src: val,
                key: val,
            })
        );

const renderHTML = (props: NotNullProperties, row: HTMLConfig) =>
    tryString(props[row.propName]).map(val =>
        DIV({
            className: `type-html`,
            dangerouslySetInnerHTML: {
                __html: val,
            },
        })
    );

const renderText = (_props: NotNullProperties, row: TextConfig) => {
    const val = row.options.text;
    return DIV(
        `type-${row.type} level-${row.options.level} style-${row.options.style}`,
        `${val}`
    );
};

const renderConfiguredRowLabel = (row: ConfigWithLabel) =>
    DIV({ className: 'feature-field-label' }, getAlias(row.propName));

const renderConfiguredRowBody = (
    props: NotNullProperties,
    row: RowConfig,
    tsPlotter: TimeseriePlotter
) => {
    const className = 'feature-field-body';
    switch (row.type) {
        case 'string':
            return DIV({ className }, renderString(props, row));
        case 'number':
            return DIV({ className }, renderNumber(props, row));
        case 'boolean':
            return DIV({ className }, renderBoolean(props, row));
        case 'url':
            return DIV({ className }, renderUrl(props, row));
        case 'image':
            return DIV({ className }, renderImage(props, row));
        case 'piechart':
            return DIV({ className }, piechart(props, row));
        case 'timeserie':
            return DIV({ className }, timeserie(tsPlotter)(props, row));
        case 'html':
            return DIV({ className }, renderHTML(props, row));
        case 'text':
            return DIV({ className }, renderText(props, row));
        case 'term':
            return DIV({ className }, renderTerm(props, row));
    }
};

const renderConfiguredRow =
    (
        props: NotNullProperties,
        tsPlotter: TimeseriePlotter,
        index: number,
        withoutZero = false
    ) =>
    (row: RowConfig) => {
        const className = 'feature-field';
        const elements: NodeOrOptional[] = [];
        const key = `feature-field-${row.propName}-${index}`;
        const property = props[row.propName];
        // if "withoutZero" property is set to true, don't show zeros, null or undefiened
        if (
            !isWidget(row.propName) &&
            withoutZero &&
            tryNumber(property).fold(
                !isNotNullNorUndefined(property),
                p => p == 0
            )
        ) {
            return none;
        }
        if (withLabel(row)) {
            if (row.options.withLabel) {
                elements.push(renderConfiguredRowLabel(row));
            }
        }
        elements.push(renderConfiguredRowBody(props, row, tsPlotter));

        return some(DIV({ className, key }, ...elements));
    };

const render = (
    feature: Feature,
    config: FeatureViewConfig,
    tsPlotter: TimeseriePlotter
) => {
    const lc = getLang();
    if (config && feature) {
        const props = feature.properties;
        const rows = config.rows.filter(r => r.lang === lc);
        if (props && rows.length > 0) {
            return DIV(
                'feature-view config',
                rows.map((r, i) =>
                    renderConfiguredRow(
                        props,
                        tsPlotter,
                        i,
                        config.withoutZeros
                    )(r)
                )
            );
        }
        if (props && rows.length === 0) {
            return renderDefault(feature);
        }
    }

    return DIV('feature-view config');
};

export default render;
